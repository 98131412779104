@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&family=DM+Sans:wght@400;500&display=swap');

html {
  width: 100vw;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  min-height: 100%;
  overflow-x: overlay;
}

table {
  text-align: left;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  min-height: 100%;
}

input {
  font-family: 'Roboto', sans-serif;
}

html {
  height: 100%;
}

::placeholder {
  color: grey;
}

* {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

b {
  font-weight: 600;
}

button {
  outline: none;
  border: none;
}

@font-face {
  font-family: 'MyFont';
  src: url(./font/din.ttf) format('woff');
  font-display: swap;
}

img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.custom-editor-play-effect {
  -webkit-animation: color-change 1s infinite;
  -moz-animation: color-change 1s infinite;
  -o-animation: color-change 1s infinite;
  -ms-animation: color-change 1s infinite;
  animation: color-change 1s infinite;
}

@-webkit-keyframes color-change {
  0% {
    color: #7361d4;
  }
  50% {
    color: #fff;
  }
  100% {
    color: #7361d4;
  }
}
@-moz-keyframes color-change {
  0% {
    color: #7361d4;
  }
  50% {
    color: #fff;
  }
  100% {
    color: #7361d4;
  }
}
@-ms-keyframes color-change {
  0% {
    color: #7361d4;
  }
  50% {
    color: #fff;
  }
  100% {
    color: #7361d4;
  }
}
@-o-keyframes color-change {
  0% {
    color: #7361d4;
  }
  50% {
    color: #fff;
  }
  100% {
    color: #7361d4;
  }
}
@keyframes color-change {
  0% {
    color: #7361d4;
  }
  50% {
    color: #fff;
  }
  100% {
    color: #7361d4;
  }
}
